import React, { useEffect, useState } from 'react'
import './Home.css'
import { Link, useNavigate } from 'react-router-dom'
import sanityClient from '../services/client.js'
import { toPlainText } from '@portabletext/react'
import { Radio } from 'react-loader-spinner'

function Home() {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [homeConfig, setHomeConfig] = useState()

  const [hero, setHero] = useState()

  const [banner, setBanner] = useState([])


  useEffect(() => {
    sanityClient.fetch(
      `*[_type == 'homeConfig']{
        hero_contains_post,
        hero_contains_banner
      }`
    )
      .then((data) => {
        console.log(data)
        setHomeConfig(data[0])
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    setLoading(true)
    sanityClient.fetch(
      `*[_type == "banner" && active == true]{
        title,
        content,
        path,
        image{
          asset->{
            _id, url
          }
        },
        isPost,
        active
      }`
    )
      .then((data) => {
        console.log(data)
        setBanner(data)
        setHero(data[0])
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const [blogs, setBlogs] = useState([])

  useEffect(() => {
    sanityClient.fetch(
      `*[_type == "post"]| order(_createdAt desc){
        title,
        content,
        path,
        image{
          asset->{
            _id, url
          }
        },
        active,
        created
      }`
    )
      .then((data) => {
        setBlogs(data)
        console.log(data);
      })
      .catch(
        console.log
      )
  }, [])

  const [updates, setUpdates] = useState([])

  useEffect(() => {
    sanityClient.fetch(
      `*[_type == 'update']{
        title,
        path
      }`
    )
      .then((data) => {
        console.log(data)
        setUpdates(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    window.tsParticles.load("tsparticles", {
      "particles": {
        "number": {
          "value": 80,
          "density": {
            "enable": true,
            "value_area": 800
          }
        },
        "color": {
          "value": "#000"
        },
        "shape": {
          "type": "circle",
          "stroke": {
            "width": 0,
            "color": "#000000"
          },
          "polygon": {
            "nb_sides": 5
          },
          "image": {
            "src": "img/github.svg",
            "width": 100,
            "height": 100
          }
        },
        "opacity": {
          "value": 0.5,
          "random": false,
          "anim": {
            "enable": false,
            "speed": 1,
            "opacity_min": 0.1,
            "sync": false
          }
        },
        "size": {
          "value": 3,
          "random": true,
          "anim": {
            "enable": false,
            "speed": 20,
            "size_min": 0.1,
            "sync": false
          }
        },
        "line_linked": {
          "enable": true,
          "distance": 150,
          "color": "#000",
          "opacity": 0.4,
          "width": 1
        },
        "move": {
          "enable": true,
          "speed": 1,
          "direction": "none",
          "random": false,
          "straight": false,
          "out_mode": "out",
          "bounce": false,
          "attract": {
            "enable": false,
            "rotateX": 600,
            "rotateY": 1200
          }
        }
      },
      "interactivity": {
        "detect_on": "canvas",
        "events": {
          "onhover": {
            "enable": true,
            "mode": "repulse"
          },
          "onclick": {
            "enable": true,
            "mode": "push"
          },
          "resize": true
        },
        "modes": {
          "grab": {
            "distance": 400,
            "line_linked": {
              "opacity": 1
            }
          },
          "bubble": {
            "distance": 400,
            "size": 10,
            "duration": 2,
            "opacity": 8,
            "speed": 3
          },
          "repulse": {
            "distance": 80,
            "duration": 0.4
          },
          "push": {
            "particles_nb": 4
          },
          "remove": {
            "particles_nb": 2
          }
        }
      },
      "retina_detect": true
    });

  }, [])

  return (
    <>
      <div id="tsparticles" className='bg-image'></div>
      <div className="bg-overlay"></div>
      <marquee behavior="scroll" direction="left" className='p-1 d-flex justify-content-center' scrollamount={0}>
        {
          updates?.map((update) => {
            return (
              <span key={update.title}>
                &nbsp;&nbsp;|&nbsp;&nbsp;<Link className='link' to={update.path.current}>{update.title}</Link>
              </span>
            )
          })
        }
        &nbsp;&nbsp;|&nbsp;&nbsp;
      </marquee>
      {
        loading ? <div className='d-flex justify-content-center align-items-center loader'>
          <Radio
            visible={true}
            height="80"
            width="80"
            ariaLabel="radio-loading"
            wrapperStyle={{}}
            wrapperClass="radio-wrapper"
            colors={['#488abe', '#3ba7d1', '#45c3cd']}
          />
        </div> :
          <>{
            hero &&
            <div className='container d-flex flex-column justify-content-center align-items-center'>
              <div className="row flex-lg-row-reverse align-items-center g-5 py-5 hero p-0 w-100">
                <div className="col-8 col-lg-6">
                  <img src={hero?.image?.asset.url} className="d-block mx-lg-auto img-fluid rounded-2 w-75" alt={hero?.title} loading="lazy" />
                </div>
                <div className="col-lg-6">
                  <h1 className="display-5 fw-bold lh-1 mb-3">{hero?.title}</h1>
                  <p className="lead">{hero?.content.length > 180 ? hero?.content.substring(0, 180) + "..." : hero?.content}</p>
                  <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                    {hero.isPost ? <button type="button" className="btn btn-secondary btn-lg px-4 ms-auto" onClick={() => navigate(hero?.path)}>Continue Reading...</button> : <></>}
                  </div>
                </div>
              </div>
            </div>}
            <div className="section-2 w-100 pt-0 d-flex flex-column align-items-center">

              <div className="custom-shape-divider-top-1682706927">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                  <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                  <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                  <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
                </svg>
              </div>
              <div className="updates-title display-5 text-center">Trending Industry Insights</div>
              <hr className="style-two" />
              <div className="blog-posts">
                {
                  blogs?.map((post) => {
                    return (
                      <article className="postcard light blue" key={post.title}>
                        <Link className="postcard__img_link" to={"/post/" + post.path.current}>
                          {post.image && <img className="postcard__img" src={post.image?.asset.url} alt={post.title} />}
                        </Link>
                        <div className="postcard__text">
                          <h1 className="postcard__title blue"><Link className='link' to={"/post/" + post.path.current}>{post.title}</Link></h1>
                          <div className="postcard__subtitle small">
                            <time dateTime="2020-05-25 12:00:00">
                              <i className="fas fa-calendar-alt mr-2"></i>{new Date(post.created).toLocaleString()}
                            </time>
                          </div>
                          <div className="postcard__bar"></div>
                          {post?.content?.length > 0 &&
                            <textarea disabled={true} className='post-meta' value={
                              toPlainText(post.content).length > 200 ?
                                toPlainText(post.content).substring(0, 200) + "..."
                                :
                                toPlainText(post.content)}
                            />}
                          {
                            post.content && toPlainText(post.content).length > 200 ? <a className='link' href={"/post/" + post.path.current}>Read More</a>
                              : <></>
                          }
                        </div>
                      </article>
                    )
                  })
                }
              </div>
            </div >
          </>
      }
    </>
  )
}

export default Home