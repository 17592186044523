import React, { useState, useEffect } from 'react'
import './AppFooter.css'
import { Link } from 'react-router-dom'
import sanityClient from '../services/client.js'
import emailjs from '@emailjs/browser';
import { useRef } from 'react';


function AppFooter() {

    const [menu, setMenu] = useState([])

    const form = useRef()

    const [sending, setSending] = useState(false)
    const [canSend, setCanSend] = useState(true)
    const handleSubmit = async (e) => {
        e.preventDefault()
        const { email } = {
            email: e.target.email.value
        };
        setSending(true)
        setCanSend(false)
        setTimeout(() => {
            setCanSend(true)
        }, 5000)
        try {
            const templateParams = {
                email
            };
            if (!canSend) {
                alert("Please wait 5 seconds before sending another message.")
                setSending(false)
                return
            }
            await emailjs.send(
                process.env.REACT_APP_SERVICE_ID,
                process.env.REACT_APP_EMAIL_TEMPLATE_ID,
                templateParams,
                process.env.REACT_APP_USER_ID
            );
            form.current.reset()
            alert("Message Sent!")
        } catch (e) {
            console.log(e);
        }
        setSending(false)
    }


    useEffect(() => {
        sanityClient.fetch(
            `*[_type == 'siteMap']{
            title,
            path
          }`
        )
            .then((data) => {
                console.log(data)
                setMenu(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const [services, setServices] = useState([])

    useEffect(() => {
        sanityClient.fetch(
            `*[_type == 'services']{
            title,
            path
          }`
        )
            .then((data) => {
                console.log(data)
                setServices(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    return (
        <div className='footer'>
            <div className="container">
                <footer className="py-5">
                    <div className="row">
                        <div className="col-6 col-md-2 mb-3">
                            <h5>Navigate</h5>
                            <ul className="nav flex-column">
                                {
                                    menu?.map((item, idx) => {
                                        if (idx < 5)
                                            return (
                                                <li key={idx} className="nav-item mb-2"><Link to={""} className="nav-link p-0 text-muted">{item.title}</Link></li>
                                            )
                                        else return <></>
                                    })
                                }
                            </ul>
                        </div>

                        <div className="col-6 col-md-2 mb-3">
                            <h5>Our Services</h5>
                            <ul className="nav flex-column">
                                {
                                    services?.map((data, idx) => {
                                        return (
                                            <li key={idx} className="nav-item mb-2"><Link to={data.path.current} className="nav-link p-0 text-muted">{data.title}</Link></li>)
                                    })
                                }
                            </ul>
                        </div>

                        <div className="col-md-5 offset-md-1 mb-3">
                            <form ref={form} onSubmit={handleSubmit}>
                                <h5>Get in touch</h5>
                                <p>Enter your email and we'll be in touch.</p>
                                <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                                    <label htmlFor="newsletter1" className="visually-hidden">Email address</label>
                                    <input id="email" type="email" className="form-control" placeholder="Email address" />
                                    <button className="btn btn-custom" type="submit">{sending ? "Sending...":"Send"}</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
                        <p>© {new Date().getFullYear()} Aysdev Global Consultancy, LLP. All rights reserved.</p>
                        <ul className="list-unstyled d-flex">
                            {/* <li className="ms-3"><Link className="link-dark" to={""}><svg className="bi" width="24" height="24"><use xlink:href="#twitter"></use></svg></Link></li>
                            <li className="ms-3"><Link className="link-dark" to={""}><svg className="bi" width="24" height="24"><use xlink:href="#instagram"></use></svg></Link></li>
                            <li className="ms-3"><Link className="link-dark" to={""}><svg className="bi" width="24" height="24"><use xlink:href="#facebook"></use></svg></Link></li> */}
                        </ul>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default AppFooter