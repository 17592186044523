import React, { useState } from 'react'
import { Container, Nav } from 'react-bootstrap'
import Navbar from 'react-bootstrap/Navbar'
import './AppNavbar.css'
import { Link } from 'react-router-dom'
import sanityClient from '../services/client.js'
import { useEffect } from 'react'

function AppNavbar() {

    const [menu, setMenu] = useState([])

    const [logo, setLogo] = useState([])

    useEffect(() => {
        sanityClient.fetch(
            `*[_type == 'logo']{
                name,
                logo{
                    asset->{
                        _id, url
                    }
                }
            }`
        )
            .then((data) => {
                console.log(data)
                setLogo(data[0])
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        sanityClient.fetch(
            `*[_type == 'nav-menu'] | order(_createdAt asc){
                title,
                path
            }`
        )
            .then((data) => {
                console.log(data)
                setMenu(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    return (
        <>
            <Navbar bg="light" variant='light' expand="lg" sticky='top'>
                <Navbar.Brand href="/" className='m-1 fw-bold brand-name d-flex align-items-center'>{logo.name} <img src={logo?.logo?.asset.url} alt="" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className='ms-auto d-flex flex-sm-column flex-md-row align-items-center'>
                        {
                            menu?.map(navLink => {
                                return (
                                    <Link to={navLink.path.current} className='m-2 link' key={navLink.title}>{navLink.title}</Link>
                                )
                            })
                        }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Dropdown
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" href="#">Action</a>
                                <a className="dropdown-item" href="#">Another action</a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="#">Something else here</a>
                            </div>
                        </li> */}

        </>
    )
}

export default AppNavbar