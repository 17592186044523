import React from 'react'
import './Contact.css'
import emailjs from '@emailjs/browser';
import { useState } from 'react';
import { useRef } from 'react';

function Contact() {

    const form = useRef()

    const [sending, setSending] = useState(false)
    const [canSend, setCanSend] = useState(true)

    const handleSubmit = async (e) => {
        e.preventDefault()
        const { name, email, organization, message } = {
            name: e.target.name.value,
            email: e.target.email.value,
            organization: e.target.organization.value,
            message: e.target.message.value
        };
        setSending(true)
        setCanSend(false)
        setTimeout(() => {
            setCanSend(true)
        }, 5000)
        try {
            const templateParams = {
                name,
                email,
                organization,
                message
            };
            if (!canSend) {
                alert("Please wait 5 seconds before sending another message.")
                setSending(false)
                return
            }
            await emailjs.send(
                process.env.REACT_APP_SERVICE_ID,
                process.env.REACT_APP_TEMPLATE_ID,
                templateParams,
                process.env.REACT_APP_USER_ID
            );
            form.current.reset()
            alert("Message Sent!")
        } catch (e) {
            console.log(e);
        }
        setSending(false)
    }

    return (
        <div className='contact-container'>
            <div className="container col-xl-10 col-xxl-8 px-4 py-5">
                <div className="row align-items-center g-lg-5 py-5">
                    <div className="col-lg-7 text-center text-lg-start">
                        <h1 className="display-4 fw-bold lh-1 mb-3">We're listening!</h1>
                        <p className="col-lg-10 fs-4">Just fill out the form with your query and we'll get back to you as soon as possible.</p>
                    </div>
                    <div className="col-md-10 mx-auto col-lg-5">
                        <form className="p-4 p-md-5 border rounded-3 bg-light" onSubmit={handleSubmit} ref={form}>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="name" placeholder="Full Name" required={true} />
                                <label for="name">Full Name</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="email" className="form-control" id="email" placeholder="name@example.com" required={true} />
                                <label for="floatingInput">Email address</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="organization" placeholder="Organization's Name" required={true} />
                                <label for="organization">Organization (if applicable)</label>
                            </div>
                            <div className="form-floating mb-3">
                                <textarea type="text" className="form-control" id="message" placeholder="Message" required={true} />
                                <label for="message">Message</label>
                            </div>
                            <button className="w-100 btn btn-lg btn-custom" type="submit">{sending ? "Sending..." : "Send"}</button>
                            <hr className="my-4" />
                            <small className="text-muted">By clicking Send, you agree to share the details given with Aysdev Global Consultancy LLP.</small>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact