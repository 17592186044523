import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from '../services/client.js'
import { PortableText } from '@portabletext/react'
import imageUrlBuilder from "@sanity/image-url";
import { Radio } from "react-loader-spinner";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source);
}

export default function OnePost() {
    const [postData, setPostData] = useState();
    const { slug } = useParams();

    useEffect(() => {
        console.log(slug);
        sanityClient
            .fetch(
                `*[path.current == $slug]{
                    title,
                    path,
                    image{
                      asset->{
                        _id,
                        url
                       }
                     },
                   content,
                   created,
                  "name": author->name,
                  "authorImage": author->image,
                  external,
                  embedCode
                 }`,
                { slug }
            )
            .then((data) => {
                console.log(data);
                setPostData(data[0])
            })
            .catch(console.error);
    }, [slug]);

    if (!postData) return <div className='d-flex justify-content-center'>
        <Radio
            visible={true}
            height="80"
            width="80"
            ariaLabel="radio-loading"
            wrapperStyle={{}}
            wrapperClass="radio-wrapper"
            colors={['#488abe', '#3ba7d1', '#45c3cd']}
        />
    </div>;

    return (
        <div>
            <header>
                <div id="intro" className="p-5 text-center bg-light">
                    <h1 className="mb-0 h4">{postData.title}</h1>
                </div>
            </header>
            <main className="">
                <div className="container">
                    <div className="row">
                        <div className="">
                            <section className="border-bottom mb-4 d-flex flex-column align-items-center">
                                <div className="col col-md-6 mx-auto m-3">
                                    <img src={postData.image?.asset.url}
                                        className="img-fluid shadow-2-strong rounded-5 w-100 border" alt="" />
                                </div>
                                {!postData.external &&
                                    <div className="row align-items-center mb-4 w-100">
                                        <div className="col-lg-6 text-center text-lg-start mb-3 m-lg-0">
                                            {postData.authorImage && <img src={urlFor(postData.authorImage).width(100).url()} className="rounded-5 shadow-1-strong me-2"
                                                height="35" alt="" loading="lazy" />}
                                            <span> Published {new Date(postData.created).toLocaleString()} by </span>
                                            <a href="" className="text-dark">{postData.name}</a>
                                        </div>
                                    </div>
                                }
                            </section>
                            <section>{
                                !postData.external ?
                                    <PortableText
                                        value={postData.content}
                                    />
                                    :
                                    <>
                                        <div dangerouslySetInnerHTML={{ __html: postData.embedCode }} className="d-flex justify-content-center"></div>
                                    </>
                            }
                            </section>
                            <section className="text-center border-top border-bottom py-4 mb-4">
                                <p><strong>Share with your friends:</strong></p>

                                <svg style={{ cursor: "pointer" }} onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href.replaceAll(":", "%3A")}`, "_blank")} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48">
                                    <path fill="#3F51B5" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"></path><path fill="#FFF" d="M34.368,25H31v13h-5V25h-3v-4h3v-2.41c0.002-3.508,1.459-5.59,5.592-5.59H35v4h-2.287C31.104,17,31,17.6,31,18.723V21h4L34.368,25z"></path>
                                </svg>

                                <svg style={{ cursor: "pointer" }} onClick={() => window.open(`https://twitter.com/intent/tweet?text=Hey!%20Check%20out%20this%20latest%20article%20by%20Aysdev%20Global%20Consultancy!%0A${window.location.href.replaceAll(":", "%3A")}`, "_blank")} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48">
                                    <path fill="#03a9f4" d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z"></path><path fill="#fff" d="M36,17.12c-0.882,0.391-1.999,0.758-3,0.88c1.018-0.604,2.633-1.862,3-3 c-0.951,0.559-2.671,1.156-3.793,1.372C31.311,15.422,30.033,15,28.617,15C25.897,15,24,17.305,24,20v2c-4,0-7.9-3.047-10.327-6 c-0.427,0.721-0.667,1.565-0.667,2.457c0,1.819,1.671,3.665,2.994,4.543c-0.807-0.025-2.335-0.641-3-1c0,0.016,0,0.036,0,0.057 c0,2.367,1.661,3.974,3.912,4.422C16.501,26.592,16,27,14.072,27c0.626,1.935,3.773,2.958,5.928,3c-1.686,1.307-4.692,2-7,2 c-0.399,0-0.615,0.022-1-0.023C14.178,33.357,17.22,34,20,34c9.057,0,14-6.918,14-13.37c0-0.212-0.007-0.922-0.018-1.13 C34.95,18.818,35.342,18.104,36,17.12"></path>
                                </svg>

                                <svg style={{ cursor: "pointer" }} onClick={() => window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href.replaceAll(":", "%3A")}`, "_blank")} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48">
                                    <path fill="#0288d1" d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z"></path><path fill="#fff" d="M14 19H18V34H14zM15.988 17h-.022C14.772 17 14 16.11 14 14.999 14 13.864 14.796 13 16.011 13c1.217 0 1.966.864 1.989 1.999C18 16.11 17.228 17 15.988 17zM35 24.5c0-3.038-2.462-5.5-5.5-5.5-1.862 0-3.505.928-4.5 2.344V19h-4v15h4v-8c0-1.657 1.343-3 3-3s3 1.343 3 3v8h4C35 34 35 24.921 35 24.5z"></path>
                                </svg>
                            </section>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}