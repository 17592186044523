import React, { useEffect, useState } from 'react'
import './About.css'
import { Carousel } from 'react-bootstrap';
import sanityClient from '../services/client.js'
import { Radio } from 'react-loader-spinner';

function About() {
    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(false);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const [slideshow, setSlideshow] = useState()

    const [leadership, setLeadership] = useState()

    const [descriptionCards, setDescriptionCards] = useState([])

    const [about, setAbout] = useState()


    useEffect(() => {
        setLoading(true)
        sanityClient.fetch(
            `*[_type == 'aboutConfig']{
                contains_slideshow,
                contains_leadership
            }`
        )
            .then((data) => {
                setAbout(data[0])
                console.log(data);
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        setLoading(true)
        sanityClient.fetch(
            `*[_type == 'aboutSlideshow']{
                heading,
                caption,
                image{
                  asset->{
                    _id, url
                  }
                }
            }`
        )
            .then((data) => {
                setSlideshow(data)
                console.log(data);
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        setLoading(true)
        sanityClient.fetch(
            `*[_type == 'aboutLeadership']{
                name,
                bio,
                image{
                  asset->{
                    _id, url
                  }
                }
            }`
        )
            .then((data) => {
                setLeadership(data)
                console.log(data);
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }, [])


    useEffect(() => {
        setLoading(true)
        sanityClient.fetch(
            `*[_type == 'aboutDescription']{
                headingPhrase,
                subtitlePhrase,
                content,
                image{
                  asset->{
                    _id, url
                  }
                }
            }`
        )
            .then((data) => {
                setDescriptionCards(data)
                console.log(data);
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }, [])


    return (
        <>
            {
                loading ? <div className='d-flex justify-content-center align-items-center loader'>
                    <Radio
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="radio-loading"
                        wrapperStyle={{}}
                        wrapperClass="radio-wrapper"
                        colors={['#488abe', '#3ba7d1', '#45c3cd']}
                    />
                </div> :
                    <div>
                        <main role="main">
                            {about?.contains_slideshow ?
                                <Carousel activeIndex={index} onSelect={handleSelect} className='about-carousel'>
                                    {
                                        slideshow?.map((item, idx) => {
                                            return (
                                                <Carousel.Item key={idx}>
                                                    <div className="overlay">
                                                    </div>
                                                    <img
                                                        className="d-block w-100"
                                                        src={item.image.asset.url}
                                                        alt={item.heading}
                                                    />
                                                    <Carousel.Caption>
                                                        <h3>{item.heading}</h3>
                                                        <p>{item.caption}</p>
                                                    </Carousel.Caption>
                                                </Carousel.Item>
                                            )
                                        })
                                    }
                                </Carousel>
                                :
                                <></>
                            }

                            <div className="container marketing leadership p-3">
                                {about?.contains_leadership ?
                                    <>
                                        <h3 className='display-5 text-center p-3'>Our Leadership</h3>
                                        <div className="row text-center justify-content-center">
                                            {
                                                leadership?.map((item, idx) => {
                                                    return (
                                                        <div className="col-lg-4" key={idx}>
                                                            <img className="rounded-circle" src={item.image.asset.url} alt="Generic placeholder image" width="140" height="140" />
                                                            <h2>{item.name}</h2>
                                                            <p>{item.bio}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </>
                                    :
                                    <></>
                                }

                                <hr className="featurette-divider" />
                                {
                                    descriptionCards.map((data, idx) => {
                                        return (
                                            <>
                                                <div className="row featurette p-3">
                                                    <div className={`col-md-7 ${idx % 2 !== 0 && "order-md-2"}`}>
                                                        <h2 className="featurette-heading">{data.headingPhrase}&nbsp;<span className="text-muted">{data.subtitlePhrase}</span></h2>
                                                        <p className="lead">{data.content}</p>
                                                    </div>
                                                    <div className={`col-md-5 ${idx % 2 !== 0 && "order-md-1"}`}>
                                                        <img className="featurette-image img-fluid mx-auto" data-src="holder.js/500x500/auto" alt="500x500" style={{ width: "100%", height: "100%", objectFit: "cover" }} src={data.image?.asset.url} data-holder-rendered="true" />
                                                    </div>
                                                </div>
                                                <hr className="featurette-divider" />
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </main>
                    </div>

            }
        </>
    )
}

export default About