import React, { useState } from 'react'
import './Clients.css'
import sanityClient from '../services/client.js'
import { useEffect } from 'react'
import { Radio } from 'react-loader-spinner'

function Clients() {

    const [clientDetails, setClientDetails] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        sanityClient.fetch(
            `*[_type == 'clientsList']{
                name,
                designation,
                details,
                clientImage{
                    asset->{
                        _id, url
                    }
                },
            }`
        )
            .then((data) => {
                console.log(data)
                setClientDetails(data)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }, [])

    const [clients, setClients] = useState([])

    useEffect(() => {
        sanityClient.fetch(
            `*[_type == 'clientsHeading']{
                pageTitle
            }`
        )
            .then((data) => {
                console.log(data)
                setClients(data[0])
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    return (
        <>

            {
                loading ? <div className='d-flex justify-content-center align-items-center loader'>
                    <Radio
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="radio-loading"
                        wrapperStyle={{}}
                        wrapperClass="radio-wrapper"
                        colors={['#488abe', '#3ba7d1', '#45c3cd']}
                    />
                </div>
                    :
                    <div className='clients-container'>
                        <h3 className='display-5 text-center p-3'>{clients?.pageTitle}</h3>
                        <hr className="featurette-divider" />
                        {
                            clientDetails.map((data, idx) => {
                                return (

                                    <section className="p-4 p-md-5 text-center text-lg-start shadow-1-strong rounded">
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-md-10">
                                                <div className="card client-card">
                                                    <div className="card-body m-3">
                                                        <div className="row">
                                                            <div className={`col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0 ${idx % 2 !== 0 && "order-md-1"}`}>
                                                                {data.clientImage && <img src={data.clientImage.asset.url} className="rounded-circle img-fluid shadow-1" alt="woman avatar" width="200" height="200" />}
                                                            </div>
                                                            <div className="col-lg-8">
                                                                <p className="text-muted fw-light mb-4">
                                                                    {data.details}
                                                                </p>
                                                                <p className="fw-bold lead mb-2"><strong>{data.name}</strong></p>
                                                                <p className="fw-bold text-muted mb-0">{data.designation}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                )
                            })
                        }
                    </div>
            }
        </>
    )
}

export default Clients